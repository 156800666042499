// nuxt-ui/Shared/composables/season/matches.ts

import type { TDateISO } from "~/src/Shared/DateTypes";
import type { DateTimeFormatOptions } from "@intlify/core-base";
import type { UseFetchParams } from "~/src/Shared/Types";
import type { CountryTimeZone } from "~/src/match/domain/models/Match";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import CountryTimeZoneData from "~/src/match/datasources/TimezoneCountryEquivalence.json";

interface UseMatches {
  useFetchParamsMatchData: (matchId: number, live: boolean) => UseFetchParams;
  formatMatchTime: (
    locale: string,
    matchDate: TDateISO,
    fallbackTimeZone: string,
    info: "stadium" | "country",
    infoId: number
  ) => string;
  setMatchTimeZone: (info: "stadium" | "country", infoId: number) => CountryTimeZone | undefined;
  showDateTime: (time: string) => boolean;
}

export const useMatches = (): UseMatches => {
  return {
    useFetchParamsMatchData,
    formatMatchTime,
    setMatchTimeZone,
    showDateTime,
  };

  function useFetchParamsMatchData(matchId: number, live: boolean): UseFetchParams {
    const { region } = useCompetitionStore();

    const path = `/api/v1/competition/matches/${matchId}`;
    const opts = {
      query: { live, competitionId: region?.competitionId },
    };

    return { path, opts };
  }

  function setMatchTimeZone(info: "stadium" | "country", infoId: number): CountryTimeZone | undefined {
    const timeZoneCountriesData: CountryTimeZone[] = CountryTimeZoneData;

    if (info === "stadium") {
      return timeZoneCountriesData.find(c => c.stadiums?.includes(infoId));
    } else if (info === "country") {
      return timeZoneCountriesData.find(c => c.id === infoId);
    } else {
      return undefined;
    }
  }

  function formatMatchTime(
    locale: string,
    matchDate: TDateISO,
    fallbackTimeZone: string,
    info: "stadium" | "country",
    infoId: number
  ): string {
    const timeZoneInfo = setMatchTimeZone(info, infoId);

    const dateOptions: DateTimeFormatOptions = {
      timeStyle: "short",
      timeZone: timeZoneInfo?.timezone || fallbackTimeZone,
    };

    return new Date(matchDate).toLocaleString(locale, dateOptions) + " " + (timeZoneInfo?.appendix || "");
  }

  function showDateTime(time: string): boolean {
    return !time.startsWith("0:00") && !time.startsWith("00:00") && !time.startsWith("12:00 AM");
  }
};
